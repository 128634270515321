import React, { useEffect, useState } from "react";
import styled from "styled-components";

import recordsJson from "../../../public/data/records.json";
import { PAGE_SIZE, CATEGORIES } from "../../constants";

import { getQueryParams, getString } from "../../helpers";
import { withFirebase } from "../Firebase";
import Pagination from "./Pagination";
import Record from "./Record";
import InJukeboxList from "./InJukeboxList";

const StyledRecordList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 90vh;
  > p {
    line-height: 7rem;
    width: 100%;
    text-align: center;
  }
`;

const RecordsList = (props) => {
  const {
    firebase,
    location,
    pageContext: { currentPage } = {},
  } = props;
  const {
    categorie,
    searchTerm,
    sortBy,
    sortOrder,
    coverId,
  } = getQueryParams(location);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    if (firebase) {
      const lastUpdatedRef = firebase.meta("lastUpdated");
      lastUpdatedRef.on("value", (snapshot) => {
        setLastUpdated(snapshot.val());
      });
    }
  });

  if (process.browser) {
    localStorage.removeItem("records");
    const localLastUpdated = localStorage.getItem("lastUpdated") || 0;
    if (
      lastUpdated &&
      parseInt(localLastUpdated, 10) !== lastUpdated
    ) {
      localStorage.setItem(
        "localRecords",
        JSON.stringify(recordsJson),
      );
    }
  }

  // convert and remove "deleted" records, or filter by categorie
  const localRecords =
    process.browser && localStorage.getItem("localRecords")
      ? JSON.parse(localStorage.getItem("localRecords"))
      : recordsJson;

  let records = Object.values(localRecords).filter((record) => {
    const catId = parseInt(record.cat_id, 10);
    const cover_id = parseInt(record.cover_id, 10);
    if (categorie) {
      return catId === categorie;
    }
    if (coverId) {
      return cover_id === parseInt(coverId, 10);
    }
    return (
      catId === CATEGORIES.NOT_IN_JUKEBOX.ID ||
      catId === CATEGORIES.IN_JUKEBOX.ID
    );
  });

  let displayedRecords;
  let results = [...records];

  // filter by search
  if (searchTerm) {
    results = results.filter(
      (record) =>
        record.artist.toLowerCase().indexOf(searchTerm) !== -1 ||
        record.a_side.toLowerCase().indexOf(searchTerm) !== -1 ||
        record.b_side.toLowerCase().indexOf(searchTerm) !== -1,
    );
  }

  // sort results
  const dynamicSort = (property) => {
    let order = 1;
    if (sortOrder === "DESC") {
      order = -1;
    }
    return (a, b) => {
      const result =
        a[property] < b[property]
          ? -1
          : a[property] > b[property]
          ? 1
          : 0;
      return result * order;
    };
  };

  // either sort search results, or all records
  displayedRecords = searchTerm
    ? [...results].sort(dynamicSort(sortBy))
    : [...records].sort(dynamicSort(sortBy));

  if (categorie === CATEGORIES.IN_JUKEBOX.ID) {
    return (
      <InJukeboxList records={displayedRecords} firebase={firebase} />
    );
  }

  // for all other pages, show only up to <pageSize> results
  displayedRecords = displayedRecords.splice(
    (currentPage - 1) * PAGE_SIZE,
    PAGE_SIZE,
  );

  return (
    <>
      <Pagination
        totalRecords={results.length}
        sortBy={sortBy}
        sortOrder={sortOrder}
        searchTerm={searchTerm}
        currentPage={currentPage}
        position="top"
        location={location}
      />

      <StyledRecordList>
        {displayedRecords.length > 0 ? (
          displayedRecords.map((record) => (
            <Record
              record={record}
              categorie={categorie}
              key={record.id}
            />
          ))
        ) : (
          <p>
            {getString("NO_RESULTS")} <span role="img">😟</span>
          </p>
        )}
      </StyledRecordList>
      <Pagination
        totalRecords={results.length}
        sortBy={sortBy}
        sortOrder={sortOrder}
        searchTerm={searchTerm}
        currentPage={currentPage}
        position="bottom"
        location={location}
      />
    </>
  );
};

export default withFirebase(RecordsList);
