import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ROUTES } from "../../constants";
import { CATEGORIES } from "../../constants";

const Label = styled.div`
  border: solid 5px Indianred;
  text-align: center;
  overflow-x: hidden;
  background: white;
  box-shadow: 4px 4px 5px #333;
  margin: 0 0 2px;

  h4,
  h5 {
    text-transform: uppercase;
    font-family: Courier, Monaco, monospace;
    font-size: 0.9rem;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
      background: lightyellow;
    }
  }

  // Artist
  h4 {
    border: solid px Indianred;
    position: relative;
    font-weight: 500;
    line-height: 0;
    a {
      background: LavenderBlush;
      border: 2px solid Indianred;
      display: inline-block;
      width: 100%;
      margin: auto;
      padding: 0 2px;
      line-height: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h4:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-left: 30px solid Indianred;
    border-bottom: 30px solid transparent;
    position: absolute;
    left: 0;
    top: -1rem;
  }

  h4:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-right: 30px solid Indianred;
    border-bottom: 30px solid transparent;
    position: absolute;
    right: 0;
    top: -1rem;
  }

  // A/B Side
  h5 {
    width: 90%;
    height: 1.2rem;
    overflow: hidden;
    margin: 0.2rem auto 0;
  }
`;

// const
const JukeboxLabel = ({ record, categorie }) => {
  const { artist, a_side, b_side, slug, id } = record;
  let recordsRouteHash = "";
  if (categorie && categorie === CATEGORIES.FOR_SALE.ID) {
    recordsRouteHash = "#for_sale";
  } else if (categorie && categorie === CATEGORIES.IN_JUKEBOX.ID) {
    recordsRouteHash = "#in_jukebox";
  }

  const recordUrl = slug
    ? `${ROUTES.RECORD}${slug}`
    : `${ROUTES.RECORD_BY_ID}?${id}`;

  return (
    <Label>
      <Link to={recordUrl} title="Bekijk deze single">
        <h5 title={a_side}>{a_side}</h5>
      </Link>
      <h4>
        <Link
          to={`${ROUTES.RECORDS}?${encodeURI(
            artist,
          ).toLowerCase()}${recordsRouteHash}`}
          title={`Bekijk alle singles van ${artist}`}
        >
          {artist}
        </Link>
      </h4>
      <Link to={recordUrl} title="Bekijk deze single">
        <h5 title={b_side}>{b_side}</h5>
      </Link>
    </Label>
  );
};

JukeboxLabel.propTypes = {
  record: PropTypes.shape({
    artist: PropTypes.string.isRequired,
    a_side: PropTypes.string.isRequired,
    b_side: PropTypes.string.isRequired,
    slug: PropTypes.string,
  }),
  categorie: PropTypes.number,
};

export default JukeboxLabel;
