import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

import {
  getQueryParams,
  getUpdatedUrl,
  getString,
} from "../../helpers";
import { PAGE_SIZE } from "../../constants";

export const StyledPagination = styled.div`
  border: 1px solid grey;
  background: white;
  padding: 0.5rem;
  box-shadow: 1px 1px 3px #333;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &.top {
    margin-top: -1rem;
    border-radius: 0 0 10px 10px;
    border-top: none;
  }
  &.bottom {
    margin-bottom: -1rem;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
  }
  > div {
    display: flex;
    flex: 1;
    justify-content: space-around;
    // align-items: baseline;
    &:nth-child(1) {
      justify-content: flex-start;
      b {
        margin: 0 3px;
      }
      @media (max-width: 1024px) {
        flex: 2;
        order: 1;
        line-height: 2rem;
        span {
          display: none;
        }
      }
    }
    &:nth-child(2) {
      flex: 2;
      // on mobile, but this below
      @media (max-width: 1024px) {
        order: 3;
        flex: 1;
        flex-direction: column;
      }
    }
    &:nth-child(3) {
      justify-content: flex-end;
      a:first-child {
        margin-right: 0.5rem;
      }
      button {
        width: 7rem;
      }
      @media (max-width: 1024px) {
        flex: 1;
        order: 2;
        button {
          width: 2rem;
        }
      }
    }
  }

  form {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      margin-top: 0.5rem;
    }
    label {
      display: flex;
      align-items: center;
      line-height: 1.1rem;
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  input[name="search"] {
    width: 14rem;
    padding: 2px 5px 3px;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  select {
    width: 12rem;
  }
  button {
    width: 2rem;
    line-height: 1rem;
    margin-left: 0.5rem;
    padding: 0.25rem;
    @media (max-width: 1024px) {
      width: 2rem;
      height: 2rem;
      span {
        display: none;
      }
    }
  }
  span {
    display: inline-block;
    cursor: pointer;
    padding-left: 8px;
    white-space: nowrap;
  }
  .active {
    color: orangered;
  }
  @media print {
    display: none;
  }
`;

const Pagination = ({
  currentPage,
  totalRecords,
  position,
  location,
}) => {
  const {
    searchTerm,
    sortBy,
    sortOrder,
    categorie,
    coverId,
  } = getQueryParams(location);
  const [pageParams, setPageParams] = useState({
    pageNum: currentPage,
    searchTerm,
    sortBy,
    sortOrder,
    categorie,
    coverId,
  });

  useEffect(() => {
    navigate(getUpdatedUrl(pageParams));
  }, [pageParams]);

  const getNextPageUrl = () =>
    getUpdatedUrl({
      ...pageParams,
      pageNum: currentPage + 1,
    });

  const getPrevPageUrl = () =>
    getUpdatedUrl({
      ...pageParams,
      pageNum: currentPage - 1 === 1 ? "" : currentPage - 1,
    });

  const totalPages = Math.ceil(totalRecords / PAGE_SIZE);

  return (
    <StyledPagination
      className={position === "bottom" ? "bottom" : "top"}
    >
      <div>
        {getString("PAGE")} <b>{currentPage}</b>
        {getString("N_OF_N")}
        {totalPages} ({totalRecords}{" "}
        <span>{getString("RECORDS")}</span>)
      </div>

      <div>
        <form>
          <input
            type="text"
            id="search"
            name="search"
            placeholder={getString("SEARCH_FOR_ARTIST_OR_TITLE")}
            value={pageParams.searchTerm}
            onChange={(e) => {
              setPageParams({
                ...pageParams,
                searchTerm: e.target.value,
              });
            }}
          />
        </form>
        <form>
          <select
            onChange={(e) =>
              setPageParams({
                ...pageParams,
                pageNum: 1,
                sortBy: e.target.value,
                sortOrder:
                  e.target.value === "lastUpdated" ? "DESC" : "ASC",
              })
            }
            value={pageParams.sortBy}
            id="sortBy"
          >
            <option value="artist">
              {getString("SORT_BY_ARTIST")}
            </option>
            <option value="lastUpdated">
              {getString("SORT_BY_UPDATED")}
            </option>
          </select>
          <button
            onClick={(e) => {
              e.preventDefault();
              setPageParams({
                ...pageParams,
                pageNum: 1,
                sortOrder: "ASC",
              });
            }}
            className={
              pageParams.sortOrder === "ASC" ? "active" : undefined
            }
            title={getString("SORT_ASCENDING")}
          >
            &#9650;
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setPageParams({
                ...pageParams,
                pageNum: 1,
                sortOrder: "DESC",
              });
            }}
            className={
              pageParams.sortOrder === "DESC" ? "active" : undefined
            }
            title={getString("SORT_DESCENDING")}
          >
            &#9660;
          </button>
        </form>
      </div>

      <div>
        <Link to={getPrevPageUrl()}>
          <button disabled={currentPage === 1} rel="prev">
            ← <span>{getString("PREVIOUS")}</span>
          </button>
        </Link>

        <Link to={getNextPageUrl()}>
          <button disabled={currentPage === totalPages} rel="next">
            <span>{getString("NEXT")}</span> →
          </button>
        </Link>
      </div>
    </StyledPagination>
  );
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  searchTerm: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
};

export default Pagination;
