import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Account/Session";
import { ROLES, ROUTES, CATEGORIES } from "../../constants";
import JukeboxLabel from "./JukeboxLabel";
import PositionSelector from "./PositionSelector";
import {
  getCategoryName,
  getCoverType,
  saveRecord,
  getString,
} from "../../helpers";

const StyledRecord = styled.div`
  display: flex;
  flex-basis: 96%;
  margin: 1%;
  position: relative;
  transition: all 0.5s ease-out;
  section {
    flex: 1;
  }
  // iphone6 horizontal
  @media (min-width: 667px) {
    flex-basis: 48%;
  }
  @media (min-width: 768px) {
    flex-basis: 31.333333%;
  }
  @media (min-width: 1024px) {
    flex-basis: 23%;
  }
  @media (min-width: 1600px) {
    flex-basis: 18%;
  }
  @media print {
    flex-basis: 31%;
  }
`;
const StyledCover = styled.div`
  background-color: transparent;
  width: 100%;
  perspective: 1000px;
  margin-bottom: 5px;
  img {
    width: 100%;
  }

  > div {
    display: block;
    position: relative;
    padding-bottom: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    box-shadow: 4px 4px 5px #333;
  }

  .cover-front,
  .cover-back {
    position: absolute;
    backface-visibility: hidden;
  }

  .cover-front {
    width: 100%;
    padding-bottom: 100%;
    background-image: url(${(props) => props.imgUrl});
    background-size: cover;
  }

  .cover-back {
    transform: rotateY(180deg);
    background-color: #333;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    font-size: 0.9rem;
    li {
      text-align: left;
      padding: 3% 0;
    }
    a {
      color: white;
      text-decoration: none;
    }
  }

  &:hover > div {
    transform: rotateY(180deg);
    box-shadow: -4px 4px 5px #333;
  }
  @media print {
    display: none;
  }
`;

const Record = ({ record, firebase, categorie }) => {
  const addToJukebox = (e) => {
    e.preventDefault();
    saveRecord(firebase, {
      ...record,
      cat_id: CATEGORIES.IN_JUKEBOX.ID,
    });
  };

  const changeJukeboxPosition = (position) => {
    const updatedRecord = { ...record };
    if (position === "remove") {
      updatedRecord.cat_id = CATEGORIES.NOT_IN_JUKEBOX.ID;
      updateRecord.position = null;
    } else {
      updatedRecord.position = position;
    }
    saveRecord(firebase, updatedRecord);
  };

  const imgUrl =
    parseInt(record.cover_id, 10) === 5
      ? record.imgUrl
      : `/img/cover_${record.cover_id}.png`;

  const recordUrl = record.slug
    ? `${ROUTES.RECORD}${record.slug}`
    : `${ROUTES.RECORD_BY_ID}?${record.id}`;

  const catId = parseInt(record.cat_id, 10);

  return (
    <AuthUserContext.Consumer>
      {(authUser) => {
        return (
          <StyledRecord key={record.id}>
            <section>
              <StyledCover imgUrl={imgUrl}>
                <div>
                  <div
                    className="cover-front"
                    key={`img-${record.id}`}
                  />
                  <div className="cover-back">
                    <ul>
                      {catId === CATEGORIES.FOR_SALE.ID && (
                        <li>
                          <a
                            href={`https://www.discogs.com/seller/dirkkater/profile?q=${encodeURI(
                              record.artist,
                            )}+${encodeURI(record.a_side)}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>🛒</span>{" "}
                            {getString("BUY_ON_DISCOGS")}
                          </a>
                        </li>
                      )}
                      {record.youtube_id1 !== "" && (
                        <li>
                          <a
                            href={`https://youtube.com/watch?v=${record.youtube_id1}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {getString("PLAY_A_SIDE")}
                          </a>
                        </li>
                      )}
                      {record.youtube_id2 !== "" && (
                        <li>{getString("PLAY_B_SIDE")}</li>
                      )}
                      <li>
                        {getString("CATEGORY")}:{" "}
                        {getCategoryName(catId)}
                      </li>
                      <li>
                        {getString("COVER_TYPE")}:{" "}
                        {getCoverType(record.cover_id)}
                      </li>
                      <li>
                        {getString("LAST_UPDATED")}:{" "}
                        {new Date(
                          record.lastUpdated,
                        ).toLocaleDateString()}
                      </li>
                      {/* <li>Voeg toe aan Jouw Playlist</li> */}
                      {!!authUser?.roles[ROLES.ADMIN] && (
                        <>
                          <hr />
                          <li>
                            {record.cat_id ===
                            CATEGORIES.IN_JUKEBOX.ID ? (
                              <>
                                <span>Positie: </span>
                                <PositionSelector
                                  value={record.position}
                                  handleChange={changeJukeboxPosition}
                                />
                              </>
                            ) : (
                              <a href="#" onClick={addToJukebox}>
                                <span>✍️</span>{" "}
                                {getString("LAST_UPDATED")}
                              </a>
                            )}
                          </li>
                          <li>
                            <Link to={`${recordUrl}#edit`}>
                              <span>✍️</span>{" "}
                              {getString("EDIT_RECORD")}
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </StyledCover>
              <JukeboxLabel record={record} categorie={categorie} />
            </section>
          </StyledRecord>
        );
      }}
    </AuthUserContext.Consumer>
  );
};

Record.propTypes = {
  record: PropTypes.object.isRequired,
  categorie: PropTypes.number,
};

export default withFirebase(Record);
