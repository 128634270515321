import React from "react";

import Layout from "../layout";
import RecordsList from "./RecordsList";

const Records = (props) => {
  const { location } = props;
  return (
    <Layout location={location}>
      <RecordsList {...props} />
    </Layout>
  );
};

export default Records;
