import React from "react";
import PropTypes from "prop-types";

import {
  JUKEBOX_SECTIONS,
  JUKEBOX_SECTION_LENGTH,
} from "../../constants";

const PositionSelector = ({ value, handleChange }) => (
  <select
    value={value}
    onChange={(e) => {
      handleChange(e.target.value);
    }}
  >
    <option value="remove">Niet meer in Jukebox</option>
    {JUKEBOX_SECTIONS.map((section) => (
      <optgroup
        label={`${section[0]} - ${section[1]}`}
        key={`${section[0]} - ${section[1]}`}
      >
        {Array(JUKEBOX_SECTION_LENGTH)
          .fill()
          .map((x, i) => (
            <option
              value={`${section[0]}${i + 1}`}
              key={`${section[0]}${i + 1}`}
            >
              {`${section[0]}${i + 1}`} / {`${section[1]}${i + 1}`}
            </option>
          ))}
      </optgroup>
    ))}
  </select>
);

PositionSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
};
export default PositionSelector;
