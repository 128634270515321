import React, { useState } from "react";
import YouTube from "react-youtube";
import styled from "styled-components";

import { AuthUserContext } from "../Account/Session";
import {
  ROLES,
  CATEGORIES,
  JUKEBOX_SECTIONS,
  JUKEBOX_SECTION_LENGTH,
} from "../../constants";

import { saveRecord, shuffle, getString } from "../../helpers";
import JukeboxLabel from "./JukeboxLabel";
import PositionSelector from "./PositionSelector";

const StyledJukeboxList = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    .list {
      max-height: calc(100vh - 200px);
      overflow: auto;
    }
  }
  > div {
    flex: 1;
  }
  ul {
    padding: 0;
    margin: 0;
    @media (min-width: 768px) {
      margin-right: 1rem;
    }
  }
  li {
    list-style: none;
    display: flex;
    padding: 1rem 1rem 1rem 0;
    border-bottom: 1px dashed darkgrey;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
    @media (min-width: 768px) {
      padding-right: 4rem;
    }
    &.active {
      background-color: lightyellow;
    }
    > div {
      &:nth-child(1) {
        padding-right: 1rem;
        div {
          display: flex;
          justify-content: space-between;
          font-size: 1.5rem;
          line-height: 1.5rem;
          select,
          div {
            margin: 0.5rem 0;
            width: 4rem;
          }
        }
        @media (max-width: 768px) {
          padding-right: 0.5rem;
          select {
            font-size: 0.8rem;
            width: 3rem;
          }
        }
      }
      &:nth-child(2) {
        flex: 1;
        max-width: 500px;
      }
      &.empty {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
  .playercontrols {
    text-align: center;
    span {
      font-size: 2rem;
      margin: 1rem;
    }
    @media (min-width: 768px) {
      width: 60%;
      margin: auto;
    }
  }
  span[rel="img"] {
    cursor: pointer;
  }
`;

const InJukeboxList = ({ records, firebase }) => {
  const [youtubeId, setYoutubeId] = useState(null);
  const [youtubePlayer, setYoutubePlayer] = useState(null);
  const [youtubeIsPaused, setYoutubeIsPaused] = useState(true);

  const changeJukeboxPosition = (position, record) => {
    const updatedRecord = { ...record };
    if (position === "remove") {
      updatedRecord.cat_id = CATEGORIES.NOT_IN_JUKEBOX.ID;
      updateRecord.position = null;
    } else {
      updatedRecord.position = position;
    }
    saveRecord(firebase, updatedRecord);
  };

  const unassignedRecords = records.filter(
    (record) => !record.position,
  );

  // you all Youtube IDs and shuffle them
  let listOfYoutubeIds = [];
  records.forEach((record) => {
    if (record.youtube_id1.length > 0) {
      listOfYoutubeIds.push(record.youtube_id1);
    }
    if (record.youtube_id2.length > 0) {
      listOfYoutubeIds.push(record.youtube_id2);
    }
  });
  listOfYoutubeIds = shuffle(listOfYoutubeIds);

  const playPreviousSong = () => {
    const index = listOfYoutubeIds.indexOf(youtubeId);
    setYoutubeId(listOfYoutubeIds[index - 1]);
    youtubePlayer.playVideo();
  };

  const playNextSong = () => {
    const index = listOfYoutubeIds.indexOf(youtubeId);
    setYoutubeId(listOfYoutubeIds[index + 1]);
    youtubePlayer.playVideo();
  };

  const playPause = () => {
    if (
      youtubePlayer.getPlayerState() !== YouTube.PlayerState.PLAYING
    ) {
      youtubePlayer.playVideo();
    } else {
      youtubePlayer.pauseVideo();
    }
  };

  const onYoutubePlayerStateChange = (e) => {
    switch (e.target.getPlayerState()) {
      case YouTube.PlayerState.ENDED:
        playNextSong();
        break;
      case YouTube.PlayerState.PLAYING:
        setYoutubeIsPaused(false);
        break;
      case YouTube.PlayerState.PAUSED:
        setYoutubeIsPaused(true);
        break;
      case YouTube.PlayerState.CUED:
        youtubePlayer.playVideo();
        break;
    }
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => {
        return (
          <>
            {!!authUser?.roles[ROLES.ADMIN] &&
              Object.keys(unassignedRecords).length > 0 && (
                <div>
                  <h2>
                    {getString("THESE_RECORDS_HAVE_NO_POSITION")}{" "}
                  </h2>
                  {unassignedRecords.map((record) => (
                    <div>
                      <div>
                        {record.artist} - {record.a_side}
                      </div>
                      <span>- {getString("POSITION")}: </span>
                      <PositionSelector
                        value={record.position}
                        handleChange={(value) => {
                          changeJukeboxPosition(value, record);
                        }}
                      />
                    </div>
                  ))}
                  <hr />
                </div>
              )}
            <StyledJukeboxList>
              <div>
                <YouTube
                  containerClassName="videoWrapper"
                  videoId={
                    youtubeId ? youtubeId : listOfYoutubeIds[0]
                  }
                  onReady={(e) => {
                    setYoutubePlayer(e.target);
                    setYoutubeId(listOfYoutubeIds[0]);
                  }}
                  onStateChange={onYoutubePlayerStateChange}
                />
                <div className="playercontrols">
                  <p>
                    <span rel="img" onClick={playPreviousSong}>
                      ⏮️
                    </span>
                    <span rel="img" onClick={playPause}>
                      {youtubeIsPaused ? <b>▶️</b> : <b>⏸️</b>}
                    </span>
                    <span rel="img" onClick={playNextSong}>
                      ⏭️
                    </span>
                  </p>
                  <p>{getString("JUKEBOX_ON_SHUFFLE_TEXT")}</p>
                </div>
              </div>
              <div className="list">
                {JUKEBOX_SECTIONS.map((section) => (
                  <div key={`${section[0]} - ${section[1]}`}>
                    <ul>
                      {Array(JUKEBOX_SECTION_LENGTH)
                        .fill()
                        .map((x, i) => {
                          const position = `${section[0]}${i + 1}`;
                          const matchingRecords = records.filter(
                            (record) => {
                              return record.position === position;
                            },
                          );
                          if (!matchingRecords.length) {
                            return (
                              <li key={`${section[0]}${i + 1}`}>
                                <div>
                                  <div>{`${section[0]}${i + 1}`}</div>
                                  <div>
                                    <div>&nbsp;</div>
                                  </div>
                                  <div>{`${section[1]}${i + 1}`}</div>
                                </div>
                                <div className="empty">
                                  {getString(
                                    "THERE_IS_NO_SONG_SET_YET_FOR_",
                                  )}
                                  {`${section[0]}${i + 1}`} /{" "}
                                  {`${section[1]}${i + 1}`}.
                                </div>
                                <div>&nbsp;</div>
                              </li>
                            );
                          }
                          return matchingRecords.map((record) => (
                            <li
                              key={`${section[0]}${i + 1}-${
                                record.id
                              }`}
                              className={
                                record.youtube_id1 === youtubeId ||
                                record.youtube_id2 === youtubeId
                                  ? "active"
                                  : ""
                              }
                              style={{
                                backgroundImage: `url(${record.imgUrl})`,
                              }}
                            >
                              <div>
                                <div>
                                  {record.youtube_id1 ? (
                                    <span
                                      rel="img"
                                      onClick={() =>
                                        setYoutubeId(
                                          record.youtube_id1,
                                        )
                                      }
                                      title={getString("PLAY")}
                                    >
                                      {record.youtube_id1 ===
                                      youtubeId ? (
                                        <b>🎶</b>
                                      ) : (
                                        <b>▶️</b>
                                      )}
                                    </span>
                                  ) : (
                                    <span>&nbsp;</span>
                                  )}
                                  <b>{`${section[0]}${i + 1}`}</b>
                                </div>
                                <div>
                                  {!!authUser?.roles[ROLES.ADMIN] ? (
                                    <PositionSelector
                                      value={`${section[0]}${i + 1}`}
                                      handleChange={(value) => {
                                        changeJukeboxPosition(
                                          value,
                                          record,
                                        );
                                      }}
                                    />
                                  ) : (
                                    <div>&nbsp;</div>
                                  )}
                                </div>
                                <div>
                                  {record.youtube_id2 ? (
                                    <span
                                      rel="img"
                                      onClick={() =>
                                        setYoutubeId(
                                          record.youtube_id2,
                                        )
                                      }
                                      title={`${record.b_side} afspelen`}
                                    >
                                      {record.youtube_id2 ===
                                      youtubeId ? (
                                        <b>🎶</b>
                                      ) : (
                                        <b>▶️</b>
                                      )}
                                    </span>
                                  ) : (
                                    <span>&nbsp;</span>
                                  )}
                                  <b>{`${section[1]}${i + 1}`}</b>
                                </div>
                              </div>
                              <JukeboxLabel record={record} />
                            </li>
                          ));
                        })}
                    </ul>
                    <hr />
                  </div>
                ))}
              </div>
            </StyledJukeboxList>
          </>
        );
      }}
    </AuthUserContext.Consumer>
  );
};

export default InJukeboxList;
